import React, { lazy, Suspense, useEffect, useState } from "react";
import { forkJoin } from "rxjs";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useCss, k } from "kremling";
import { handleError } from "src/handle-error";
import {
  CpArea,
  CpButton,
  CpCard,
  CpCase,
  CpEmptyState,
  CpLoader,
  CpSwitch,
} from "canopy-styleguide!sofe";
import {
  getPrototype,
  getPrototypeResponses,
  updatePrototype,
} from "./prototypes.resource";
import { AddEditQuestionModal } from "./add-edit-question-modal.component";
import { DeleteQuestionModal } from "./delete-question-modal.component";
import { isEmpty } from "lodash";

const PrototypeSurvey = lazy(() =>
  SystemJS.import("toolbox!sofe")
    .then((toolbox) => toolbox.getPrototypeSurvey())
    .then((m) => m)
);

export default function Prototypes() {
  const scope = useCss(css);
  const { prototypeId } = useParams();
  const [prototype, setPrototype] = useState();
  const [canEdit, setCanEdit] = useState();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState();
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (!prototypeId) return;
    const subscription = forkJoin(
      getPrototype(prototypeId),
      getPrototypeResponses(prototypeId)
    ).subscribe((result) => {
      const [prototype, responses] = result;
      setPrototype(prototype);
      setCanEdit(isEmpty(responses));
      setLoading(false);
    }, handleError);
    return () => {
      subscription.unsubscribe();
    };
  }, [prototypeId]);

  function onSaveQuestion(question) {
    setSaving(true);
    const newQuestions = question.id
      ? prototype.questions.map((q) => (q.id === question.id ? question : q))
      : [
          ...prototype.questions,
          {
            ...question,
            //creating a unique id for each question (backend just stores an array of questions with no IDs)
            //so I can delete and eventually reorder questions, and to give react a unique key
            id: uuidv4(),
          },
        ];
    updatePrototype(prototypeId, {
      ...prototype,
      questions: newQuestions,
    }).subscribe((prototype) => {
      setPrototype(prototype);
      setSaving(false);
      setShowAddEditModal(false);
      setQuestionToEdit();
    }, handleError);
  }

  function addQuestion() {
    setQuestionToEdit();
    setShowAddEditModal(true);
  }

  function editQuestion(question) {
    setQuestionToEdit(question);
    setShowAddEditModal(true);
  }

  function deleteQuestion(question) {
    setQuestionToDelete(question);
  }

  function onDelete() {
    setSaving(true);
    updatePrototype(prototypeId, {
      ...prototype,
      questions: prototype.questions.filter(
        (q) => q.id !== questionToDelete.id
      ),
    }).subscribe((prototype) => {
      setPrototype(prototype);
      setQuestionToDelete();
      setSaving(false);
    }, handleError);
  }
  return (
    <div {...scope}>
      <CpCard>
        {loading ? (
          <CpLoader />
        ) : (
          <>
            <CpCard.Header>
              <div className="cps-subheader cps-wt-semibold">
                Prototype: {prototype.name}
              </div>
              <CpButton
                btnType="tertiary"
                onClick={() => {
                  setShowPreview(true);
                }}
              >
                Preview
              </CpButton>
            </CpCard.Header>
            <div className="question-container">
              {prototype.questions.length === 0 ? (
                <div className="cp-p-24">
                  <CpEmptyState
                    img="es_lego_head"
                    text="No Questions"
                    subText="This prototype doesn't have questions yet."
                  />
                </div>
              ) : (
                prototype.questions.map((question) => (
                  <CpArea
                    key={question.id}
                    className="question-item"
                    focusInset
                  >
                    <div>
                      <div className="cp-body">{question.question}</div>
                      <div>
                        {
                          <CpSwitch expression={question.type}>
                            <CpCase value={"scale"}>
                              {`Options: ${question.min}-${question.max}`}
                            </CpCase>
                            <CpCase value={"agree_disagree"}>
                              Options: Strongly Disagree, Disagree, Neutral,
                              Agree, Strongly Agree
                            </CpCase>
                            <CpCase value={"multiple_choice"}>
                              {`Options: ${question.options?.join(", ")}`}
                            </CpCase>
                            <CpCase value={"open_ended"}>Open ended</CpCase>
                          </CpSwitch>
                        }
                      </div>
                    </div>
                    <div>
                      <CpButton
                        icon="crud-pencil"
                        aria-label="Edit"
                        onClick={() => editQuestion(question)}
                        disabled={!canEdit}
                      />
                      <CpButton
                        icon="crud-trash-small"
                        aria-label="Delete"
                        onClick={() => deleteQuestion(question)}
                        disabled={!canEdit}
                      />
                    </div>
                  </CpArea>
                ))
              )}
            </div>
            <CpCard.Footer>
              <CpButton onClick={addQuestion} disabled={!canEdit}>
                Add question
              </CpButton>
              {!canEdit && (
                <span className="cp-caption cp-ml-24">
                  Cannot edit this prototype because a user has already
                  submitted feedback
                </span>
              )}
            </CpCard.Footer>
          </>
        )}
      </CpCard>
      <AddEditQuestionModal
        existingQuestion={questionToEdit}
        showModal={showAddEditModal}
        setShowModal={setShowAddEditModal}
        onSave={onSaveQuestion}
        saving={saving}
      />
      <DeleteQuestionModal
        question={questionToDelete}
        onDelete={onDelete}
        setQuestionToDelete={setQuestionToDelete}
        saving={saving}
      />
      <Suspense fallback={null}>
        <PrototypeSurvey
          prototypeId={prototypeId}
          show={showPreview}
          setShow={setShowPreview}
        />
      </Suspense>
    </div>
  );
}
const css = k`

  .question-container {
    background-color: var(--cp-color-select-list);
  }
  .question-item {
    padding: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid .1rem var(--cp-color-app-border);
  }
  .question-container > *:last-child {
    border: none;
  }
`;
