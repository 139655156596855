import { useState, useEffect } from "react";
import { fetchAsObservable } from "fetcher!sofe";
import { handleError } from "src/handle-error";

export function useApiQuery(url, defaultParameters, method = "GET") {
  const [parameters, _setParameters] = useState(() => defaultParameters);
  const [refetch, setRefetch] = useState(0);
  const [data, setData] = useState(null);
  const [body, setBody] = useState(method === "POST" ? {} : null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
    setData(null);

    const query = Object.keys(parameters).reduce(function toQueryString(
      query,
      parameter
    ) {
      return parameters[parameter]
        ? `${query}&${parameter}=${parameters[parameter]
            .split(",")
            .reduce((querySubTotal, value) => {
              return querySubTotal
                ? querySubTotal + `&${parameter}=${encodeURIComponent(value)}`
                : encodeURIComponent(value);
            }, "")}`
        : query;
    },
    "");

    const queryTotal = url.includes("?") ? query : `?${query.substring(1)}`;
    const subscription = fetchAsObservable(`${url}${queryTotal}`, {
      method,
      body,
    }).subscribe(
      (resp) => setData(resp),
      (error) => {
        setError(error);
        handleError(error);
      }
    );

    return () => subscription.unsubscribe();
  }, [
    refetch,
    ...Object.keys(parameters).map((parameter) => parameters[parameter]),
    body,
  ]);

  function setParameters(params) {
    _setParameters({ ...parameters, ...params });
  }

  return [
    data,
    parameters,
    setParameters,
    () => setRefetch(refetch + 1),
    setBody,
    error,
  ];
}

class NoData {}

export function useNetworkMutation(url, method, contentType = "string") {
  const [data, setRequestData] = useState(null);
  const [complete, setComplete] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (data) {
      const subscription = fetchAsObservable(url, {
        method,
        body:
          data instanceof NoData
            ? undefined
            : typeof data === "string"
            ? data
            : contentType === "string"
            ? JSON.stringify(data)
            : data,
      }).subscribe(complete, (e) => {
        setError(e);
        handleError(e);
      });

      return () => subscription.unsubscribe();
    }
  }, [data, url, complete]);

  return (data = new NoData(), complete, error) => {
    setComplete(() => complete);
    setError(() => error);
    setRequestData(data);
  };
}

export function useServerData(url, update) {
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(null);
  useEffect(() => {
    const subscription = fetchAsObservable(url).subscribe((resp) => {
      setData(resp);
      if (update) update(resp);
    }, handleError);

    return () => subscription.unsubscribe();
  }, [url, refresh]);

  return [data, setData, setRefresh];
}

export function syncZuora(zuoraId) {
  return fetchAsObservable("/wg/zuora-sync", {
    method: "POST",
    body: { zuora_id: zuoraId },
  });
}
