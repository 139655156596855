import React, { useState, useRef } from "react";
import { without, isString } from "lodash";
import { CpButton, CpIcon } from "canopy-styleguide!sofe";
import { useCss, m, k } from "kremling";
import useClickAway from "react-use/lib/useClickAway";

function useInternal(values = {}) {
  const [internals, setInternals] = useState(values);

  return [internals, (internal) => setInternals({ ...internals, ...internal })];
}

function searchValueSelected(selectedSearchValues, searchValue) {
  return selectedSearchValues.split(",").includes(searchValue);
}

export default function TableHeader({
  name,
  orderBy,
  label,
  ascDesc,
  searchValue,
  searchValues,
  searchKey,
  setParameters,
  canSort = true,
}) {
  const scoped = useCss(css);
  const [menuVisible, setMenuVisible] = useState(false);
  const [
    { orderByInternal, ascDescInternal, searchValueInternal },
    updateInternal,
  ] = useInternal({
    orderByInternal: orderBy,
    ascDescInternal: ascDesc,
    searchValueInternal: searchValue,
  });
  const menuRef = useRef(null);
  const inputRef = useRef(null);
  useClickAway(menuRef, cancel);

  function cancel(e) {
    if (e) {
      e.stopPropagation();
    }
    setMenuVisible(false);
    updateInternal({
      orderByInternal: orderBy,
      ascDescInternal: ascDesc,
      searchValueInternal: searchValue,
    });
  }

  function applySearch(e) {
    e.stopPropagation();
    setMenuVisible(false);
    setParameters({
      page: "1",
      orderBy: orderByInternal,
      ascDesc: ascDescInternal,
      [searchKey]: searchValueInternal,
    });
  }

  function reset(e) {
    e.stopPropagation();
    setMenuVisible(false);
    setParameters({
      page: "1",
      orderBy: "",
      ascDesc: "asc",
      [searchKey]: "",
    });
  }

  function keyDown(event) {
    if (event.key === "Enter") {
      applySearch(event);
    } else if (event.key === "Escape") {
      cancel(event);
    }
  }

  function selectSearchValue(searchValue, event) {
    event.stopPropagation();
    const searchValues = searchValueInternal
      .split(",")
      .filter((element) => element);

    if (searchValues.includes(searchValue)) {
      updateInternal({
        searchValueInternal: without(searchValues, searchValue).join(","),
      });
    } else {
      updateInternal({
        searchValueInternal: [...searchValues, searchValue].join(","),
      });
    }
  }

  return (
    <th
      {...scoped}
      ref={menuRef}
      className={m("active", orderBy === name || searchValue).m(
        "no-hover",
        !setParameters
      )}
      onClick={(event) => {
        if (!setParameters) return;
        event.stopPropagation();
        setMenuVisible(true);
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        });
      }}
    >
      <div className="label-wrapper">
        <span>{label}</span>
        {orderBy === name ? (
          ascDesc === "asc" ? (
            <CpIcon name="caret-small-up" />
          ) : (
            <CpIcon name="caret-small-down" />
          )
        ) : null}
        <span style={{ flex: 1 }} />
        <div
          title="Clear sort and filter"
          className="clear cps-btn-icon"
          onClick={reset}
        >
          <span className="cps-link" style={{ marginTop: 0 }}>
            <span className="cps-icon cps-icon-sm-neg" />
          </span>
        </div>
      </div>
      {menuVisible && (
        <div className="menu-wrapper">
          <div className="cps-dropdown menu cps-open">
            <div className="cps-dropdown-menu">
              {canSort && (
                <div>
                  <div
                    onClick={() =>
                      updateInternal({
                        ascDescInternal: "asc",
                        orderByInternal: name,
                      })
                    }
                    className="option"
                  >
                    <span className="cps-margin-right-8">Sort</span>
                    <b style={{ flex: 1 }}>A - Z</b>
                    {ascDescInternal === "asc" && orderByInternal === name && (
                      <CpIcon
                        fill="var(--cps-color-success)"
                        name="checkmark-circle-filled-small"
                      />
                    )}
                  </div>
                  <div
                    onClick={() =>
                      updateInternal({
                        ascDescInternal: "desc",
                        orderByInternal: name,
                      })
                    }
                    className="option"
                  >
                    <span className="cps-margin-right-8">Sort</span>
                    <b style={{ flex: 1 }}>Z - A</b>
                    {ascDescInternal === "desc" && orderByInternal === name && (
                      <CpIcon
                        fill="var(--cps-color-success)"
                        name="checkmark-circle-filled-small"
                      />
                    )}
                  </div>
                </div>
              )}
              <hr />
              {isString(searchValue) &&
                (searchValues ? (
                  <React.Fragment>
                    {searchValues.map((searchValue) => (
                      <div
                        key={searchValue}
                        className="filter"
                        onClick={selectSearchValue.bind(null, searchValue)}
                      >
                        <div className="cps-ellipsis">{searchValue}</div>
                        <div style={{ minWidth: 25, margin: -3 }}>
                          {searchValueSelected(
                            searchValueInternal,
                            searchValue
                          ) && (
                            <CpIcon
                              fill="var(--cps-color-success)"
                              name="checkmark-circle-filled-small"
                            />
                          )}
                        </div>
                      </div>
                    ))}
                    <hr />
                  </React.Fragment>
                ) : (
                  <div>
                    <input
                      ref={inputRef}
                      onKeyDown={keyDown}
                      onChange={(e) =>
                        updateInternal({ searchValueInternal: e.target.value })
                      }
                      value={searchValueInternal}
                      type="text"
                      className="cps-form-control"
                      placeholder={`Filter ${label}`}
                    />
                  </div>
                ))}
              <div className="action-wrapper">
                <CpButton onClick={applySearch} btnType="flat">
                  Apply
                </CpButton>
                <CpButton onClick={cancel} btnType="tertiary">
                  Cancel
                </CpButton>
                <span style={{ flex: 1 }} />
                <CpButton onClick={reset} btnType="tertiary">
                  Reset
                </CpButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </th>
  );
}

const css = k`
  .filter {
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--cps-color-cool-gray);
    font-weight: normal;
    padding: 8px 16px;
  }
  .filter:hover {
    background: var(--cps-color-chrome);
  }
  th {
    cursor: pointer;
  }
  th:hover, th.active {
    background-color: var(--cps-color-chrome);
  }
  th.no-hover:hover, th.no-hover.active {
    background-color: inherit;
    cursor: default;
  }
  .clear {
    display: none;
  }
  .active .clear {
    display: block;
  }
  .label-wrapper {
    display: flex;
    align-items: center;
  }
  .menu-wrapper {
    position: relative;
  }
  .menu {
    position: absolute;
    top: 12px;
  }
  .option {
    padding: 8px 16px;
    font-weight: normal;
    color: #000;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
  .option:hover {
    background-color: var(--cps-color-chrome);
  }
  input {
    margin: 16px;
    width: 220px;
  }
  .action-wrapper button {
    margin-left: 0;
  }
  .action-wrapper {
    display: flex;
    padding: 0 8px;
  }
`;
