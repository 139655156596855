import React, { useState } from "react";
import { CpButton, CpModal, CpInput } from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error";
import { postPrototype } from "./prototypes.resource";

export function CreatePrototypeModal({ showModal, setShowModal }) {
  const [creatingPrototype, setCreatingPrototype] = useState(false);
  const [name, setName] = useState("");

  const createPrototype = () => {
    const newPrototype = {
      name,
      questions: [],
    };
    setCreatingPrototype(true);
    postPrototype(newPrototype).subscribe((prototype) => {
      setCreatingPrototype(false);
      window.location = `/#/canopy-admin/prototypes/${prototype.id}`;
    }, handleError);
  };

  return (
    <>
      <CpModal show={showModal} onClose={() => setShowModal(false)}>
        <CpModal.Header title="Add a prototype user or tenant" />
        <CpModal.Body>
          <div className="cp-mb-16">
            <CpInput
              label="Prototype Name"
              placeholder="my_cool_prototype"
              value={name}
              onChange={(val) => setName(val.trim())}
              message="Alphanumeric snake case"
              required
            />
          </div>
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton
            btnType="primary"
            onClick={() => createPrototype()}
            className="cp-mr-8"
            disabled={!name}
            showLoader={creatingPrototype}
          >
            Add
          </CpButton>
          <CpButton btnType="flat" onClick={() => setShowModal(false)}>
            Cancel
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    </>
  );
}
