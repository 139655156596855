import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function getAllPrototypes() {
  return fetchAsObservable(`/prototypes`).pipe(pluck("prototypes"));
}

export const postPrototype = (prototype) => {
  return fetchAsObservable(`/prototypes`, {
    method: "POST",
    body: { prototypes: prototype },
  }).pipe(pluck("prototypes"));
};

export const updatePrototype = (prototypeId, prototype) => {
  return fetchAsObservable(`/prototypes/${prototypeId}`, {
    method: "PATCH",
    body: { prototypes: prototype },
  }).pipe(pluck("prototypes"));
};

export function getPrototype(prototypeId) {
  return fetchAsObservable(`/prototypes/${prototypeId}`).pipe(
    pluck("prototypes")
  );
}

export function archivePrototype(prototypeId) {
  return fetchAsObservable(`/prototypes/${prototypeId}`, {
    method: "DELETE",
  });
}

export function getPrototypeResponses(prototypeId) {
  return fetchAsObservable(`/prototypes/${prototypeId}/responses`).pipe(
    pluck("responses")
  );
}

export function getPrototypeResponse(prototypeId, responseID) {
  return fetchAsObservable(
    `/prototypes/${prototypeId}/responses/${responseID}`
  );
}
