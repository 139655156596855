import React from "react";
import { CpButton, CpModal } from "canopy-styleguide!sofe";

export function DeleteQuestionModal({
  question,
  onDelete,
  setQuestionToDelete,
  saving,
}) {
  return (
    <CpModal show={!!question} onClose={() => setQuestionToDelete()}>
      <CpModal.Header title="Are you sure you want to delete this?" />
      <CpModal.Body>{question?.question}</CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={() => onDelete()}
          className="cp-mr-8"
          showLoader={saving}
        >
          Yes
        </CpButton>
        <CpButton btnType="flat" onClick={() => setQuestionToDelete()}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
