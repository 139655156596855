import dateFormat from "dateformat";

export function formatDateForInput(date) {
  if (!date) return "";
  const d = new Date(date);
  return dateFormat(d, "yyyy-mm-dd");
}

export function formatDate(date, defaultValue = "") {
  if (!date) return defaultValue;
  const d = new Date(date);
  return dateFormat(d, "mm-dd-yyyy");
}

export function formatTime(date, defaultValue = "") {
  if (!date) return defaultValue;
  const d = new Date(date);
  return dateFormat(d, "h:MM TT");
}

export function formatDateWithTime(date, defaultValue = "") {
  if (!date) return defaultValue;
  const d = new Date(date);
  return dateFormat(d, "mm-dd-yyyy h:MM TT");
}

export function parseDate(date) {
  if (!date) throw new Error("Must provide a valid date");

  let [year, month, day] = date.split("-");
  year = year * 1;
  month = month * 1;
  day = day * 1;

  return new Date(year, month - 1, day);
}
