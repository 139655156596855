import React, { useEffect, useMemo, useState } from "react";
import {
  CpButton,
  CpCase,
  CpInput,
  CpLabel,
  CpModal,
  CpSelectSingle,
  CpSwitch,
  CpTextarea,
} from "canopy-styleguide!sofe";

const questionTypes = [
  { id: "scale", name: "Scale of 1-10" },
  { id: "agree_disagree", name: "Agree/Disagree" },
  { id: "multiple_choice", name: "Multiple choice" },
  { id: "open_ended", name: "Open ended" },
];

export function AddEditQuestionModal({
  existingQuestion,
  showModal,
  setShowModal,
  onSave,
  saving,
}) {
  const [question, setQuestion] = useState("");
  const [questionType, setQuestionType] = useState();
  const [multipleChoiceString, setMultipleChoiceString] = useState("");

  useEffect(() => {
    if (!existingQuestion) return;
    setQuestion(existingQuestion.question);
    setQuestionType(
      questionTypes.find((type) => type.id === existingQuestion.type)
    );
    if (existingQuestion.type === "multiple_choice") {
      setMultipleChoiceString(existingQuestion.options.join("\r\n"));
    }
  }, [existingQuestion]);

  const multipleChoiceQuestions = useMemo(
    () =>
      multipleChoiceString
        ? multipleChoiceString
            .split(/\r?\n|\r|\n/g)
            .filter((choice) => choice)
            .map((val) => val.trim())
        : "",
    [multipleChoiceString]
  );

  function canSave() {
    return (
      question &&
      questionType &&
      (questionType.id === "multiple_choice"
        ? multipleChoiceQuestions.length > 1
        : true)
    );
  }

  function onSaveQuestion() {
    let newQuestion = { type: questionType.id, question: question.trim() };
    if (questionType.id === "scale") {
      newQuestion["min"] = 1;
      newQuestion["max"] = 10;
    }
    if (questionType.id === "multiple_choice") {
      newQuestion["options"] = multipleChoiceQuestions;
    }
    if (existingQuestion) {
      newQuestion.id = existingQuestion.id;
    }
    onSave(newQuestion);
    setQuestion("");
    setQuestionType();
    setMultipleChoiceString("");
  }

  return (
    <>
      <CpModal show={!!showModal} onClose={() => setShowModal(false)}>
        <CpModal.Header
          title={`${existingQuestion ? "Edit" : "Add"} a question`}
        />
        <CpModal.Body>
          <div className="cp-mb-16">
            <CpInput
              label="Question"
              placeholder="Write your question..."
              value={question}
              onChange={(val) => setQuestion(val)}
              required
            />
          </div>
          <div className="cp-mb-16">
            <CpLabel htmlFor="questionType" required>
              Question type
            </CpLabel>
            <CpSelectSingle
              placeholder="Select a question type"
              triggerIsBlock
              data={questionTypes}
              onChange={setQuestionType}
              value={questionType}
            />
          </div>
          {questionType && (
            <div>
              <CpSwitch expression={questionType.id}>
                <CpCase value={"scale"}>Options: 1-10</CpCase>
                <CpCase value={"agree_disagree"}>
                  Options: Strongly Disagree, Disagree, Neutral, Agree, Strongly
                  Agree
                </CpCase>
                <CpCase value={"multiple_choice"}>
                  <CpTextarea
                    label="Enter choices below"
                    rows={4}
                    required
                    onChange={setMultipleChoiceString}
                    placeholder="Enter each choice on a separate line"
                    value={multipleChoiceString}
                  />
                </CpCase>
              </CpSwitch>
            </div>
          )}
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton
            btnType="primary"
            onClick={onSaveQuestion}
            className="cp-mr-8"
            disabled={!canSave()}
            showLoader={saving}
          >
            Save
          </CpButton>
          <CpButton btnType="flat" onClick={() => setShowModal(false)}>
            Cancel
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    </>
  );
}
