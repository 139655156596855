import React, { useState, useEffect } from "react";
import { useCss, m } from "kremling";
import { CpButton } from "canopy-styleguide!sofe";
import { isEqual } from "lodash";

const PAGE_COUNT = 20;

export default function CardHeader({
  title,
  subtitle,
  data,
  page,
  setParameters,
  pageCount = PAGE_COUNT,
  parameters,
}) {
  const scoped = useCss(css);
  const count = data
    ? data.meta
      ? data.meta.paginator.total_count
      : data.count
    : 0;
  const totalPages = Math.ceil(count / pageCount);
  const isLastPage = page == totalPages;
  const start = page == "1" ? 1 : pageCount * (page * 1 - 1) + 1;
  const finish = isLastPage ? count : pageCount * (page * 1);
  const [advancedSearch, setAdvancedSearch] = useState(
    localStorage.getItem("cp-admin-advanced-search-toggle") || false
  );
  const [searchParams, setSearchParams] = useState(parameters);

  useEffect(() => {
    setSearchParams({ ...parameters });
  }, [parameters]);

  function shiftPage(amount) {
    setParameters({ page: page * 1 + amount + "" });
  }

  function showAdvancedSearch(show) {
    if (show) {
      setAdvancedSearch(true);
      localStorage.setItem("cp-admin-advanced-search-toggle", true);
    } else {
      setAdvancedSearch(false);
      localStorage.removeItem("cp-admin-advanced-search-toggle");
    }
  }

  function updateSearch() {
    setParameters({
      ...searchParams,
      page: "1",
    });
  }

  function keyDown(event) {
    if (event.key === "Enter") {
      updateSearch();
    }
  }

  return (
    <div {...scoped} className="table-header-wrapper">
      <div>
        <div className="header">{title}</div>
        <div className="subheader">{subtitle}</div>
      </div>
      <div style={{ flex: 1 }} />
      {count ? (
        <div className="cps-text-right">
          <div>
            {title === "Users" && (
              <span>
                <span className="cps-padding-right-16">Advanced Search</span>
                <label className="cps-toggle cps-padding-right-32">
                  <input
                    type="checkbox"
                    checked={!!advancedSearch}
                    onChange={() => showAdvancedSearch(!advancedSearch)}
                  />
                  <span />
                </label>
              </span>
            )}
            <span>
              {start} - {finish} of {count}
            </span>
            <span className="cps-padding-12 pagination-button">
              <CpButton
                onClick={shiftPage.bind(null, -1)}
                disabled={page === "1"}
                btnType="tertiary"
              >
                Previous
              </CpButton>
            </span>
            |
            <span className="cps-padding-left-12 pagination-button">
              <CpButton
                onClick={shiftPage.bind(null, 1)}
                className="cp-ml-0"
                disabled={isLastPage}
                btnType="tertiary"
              >
                Next
              </CpButton>
            </span>
          </div>
          <div
            className={m(
              "cps-padding-top-12 pagination-button",
              advancedSearch
            )}
          >
            {title === "Users" && advancedSearch && (
              <div className="cps-form-inline">
                <div className="cp-flex">
                  <div className="cp-pr-8">
                    <label className="cp-pr-8">User ID</label>
                    <input
                      className="cps-form-control search-item-short"
                      value={searchParams.userId}
                      placeholder="User ID"
                      onKeyDown={keyDown}
                      onChange={(e) =>
                        setSearchParams({
                          ...searchParams,
                          userId: e.target.value.trim(),
                        })
                      }
                      type="text"
                    />
                  </div>
                  <div className="cp-pr-8">
                    <label className="cp-pr-8">Tenant ID</label>
                    <input
                      className="cps-form-control search-item-short"
                      placeholder="Tenant ID"
                      onKeyDown={keyDown}
                      value={searchParams.tenantId}
                      onChange={(e) =>
                        setSearchParams({
                          ...searchParams,
                          tenantId: e.target.value.trim(),
                        })
                      }
                      type="text"
                    />
                  </div>
                  <div className="cp-pr-8">
                    <label className="cp-pr-8">Name</label>
                    <input
                      className="cps-form-control search-item-long"
                      placeholder="Name"
                      onKeyDown={keyDown}
                      value={searchParams.name}
                      onChange={(e) =>
                        setSearchParams({
                          ...searchParams,
                          name: e.target.value.trim(),
                        })
                      }
                      type="text"
                    />
                  </div>
                  <div className="cp-pr-8">
                    <label className="cp-pr-8">Email</label>
                    <input
                      className="cps-form-control search-item-long"
                      placeholder="Email"
                      onChange={(e) =>
                        setSearchParams({
                          ...searchParams,
                          email: e.target.value.trim(),
                        })
                      }
                      onKeyDown={keyDown}
                      value={searchParams.email}
                      type="text"
                    />
                  </div>
                  <div className="cp-pr-8">
                    <label className="cp-pr-8">Zuora ID</label>
                    <input
                      className="cps-form-control search-item-long"
                      onKeyDown={keyDown}
                      placeholder="Zuora ID"
                      value={searchParams.zuoraId}
                      onChange={(e) =>
                        setSearchParams({
                          ...searchParams,
                          zuoraId: e.target.value.trim(),
                        })
                      }
                      type="text"
                    />
                  </div>
                  <div className="cp-pr-8">
                    <label className="submit-search-label">Submit</label>
                    <CpButton
                      onClick={updateSearch}
                      disabled={isEqual(searchParams, parameters)}
                    >
                      Submit
                    </CpButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

const css = `
  & .table-header-wrapper {
    display: flex;
    padding: 20px;
  }
  .submit-search-label {
    visibility: hidden;
  }
  & .header {
    font-weight: 400;
    font-size: 2.4rem!important;
    line-height: 3.2rem!important;
  }
  & .subheader {
    font-size: 1.2rem;
    color: var(--cp-color-app-secondary-text);
  }
  & .search-item-short {
    max-width: 90px;
  }
  & .search-item-long {
    max-width: 240px;
  }
`;
