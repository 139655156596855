import React, { useEffect, useState } from "react";
import { useCss, k } from "kremling";
import { handleError } from "src/handle-error";
import {
  CpArea,
  CpButton,
  CpCard,
  CpEmptyState,
  CpLoader,
} from "canopy-styleguide!sofe";
import { CreatePrototypeModal } from "./create-prototype-modal.component";
import { DeletePrototypeModal } from "./delete-prototype-modal.component";
import { getAllPrototypes, archivePrototype } from "./prototypes.resource";

export default function Prototypes() {
  const scope = useCss(css);
  const [showCreateModal, setCreateShowModal] = useState(false);
  const [prototypes, setPrototypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [prototypeToDelete, setPrototypeToDelete] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setLoading(true);
    const sub = getAllPrototypes().subscribe((prototypes) => {
      setPrototypes(prototypes);
      setLoading(false);
    }, handleError);
    return () => {
      sub.unsubscribe();
    };
  }, []);

  function deletePrototype(prototype) {
    setPrototypeToDelete(prototype);
    setShowDeleteModal(true);
  }

  function onDelete(prototype) {
    setSaving(true);
    archivePrototype(prototype.id).subscribe(() => {
      setPrototypes(() => prototypes.filter((p) => p.id !== prototype.id));
      setShowDeleteModal(false);
      setSaving(false);
    }, handleError);
  }

  return (
    <div {...scope}>
      <CpCard>
        <CpCard.Header>
          <div className="cps-subheader cps-wt-semibold">Prototypes</div>
          <CpButton btnType="primary" onClick={() => setCreateShowModal(true)}>
            Add prototype
          </CpButton>
        </CpCard.Header>
        {loading ? (
          <CpLoader />
        ) : prototypes.length ? (
          <div className="prototype-container">
            {prototypes.map((prototype) => (
              <CpArea
                key={prototype.id}
                className="prototype-item cp-body"
                onClick={() => {
                  window.location = `/#/canopy-admin/prototypes/${prototype.id}`;
                }}
                focusInset
              >
                <div>{prototype.name}</div>
                <div>
                  <CpButton
                    icon="crud-trash-small"
                    aria-label="Delete"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      deletePrototype(prototype);
                    }}
                  />
                </div>
              </CpArea>
            ))}
          </div>
        ) : (
          <div className="cp-p-24">
            <CpEmptyState
              img="es_lego_head"
              text="No Prototypes"
              subText="There are currently no active prototypes."
              cta={
                <CpButton
                  btnType="primary"
                  onClick={() => setCreateShowModal(true)}
                >
                  Add a prototype
                </CpButton>
              }
            />
          </div>
        )}
      </CpCard>
      <CreatePrototypeModal
        showModal={showCreateModal}
        setShowModal={setCreateShowModal}
      />
      <DeletePrototypeModal
        prototype={prototypeToDelete}
        onYes={onDelete}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        saving={saving}
      />
    </div>
  );
}
const css = k`
  .prototype-container {
    background-color: var(--cp-color-select-list);
  }
  .prototype-item {
    padding: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid .1rem var(--cp-color-app-border);
  }
  .prototype-container > *:last-child {
    border: none;
  }
`;
