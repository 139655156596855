import React from "react";
import { CpButton, CpModal } from "canopy-styleguide!sofe";

export function DeletePrototypeModal({
  prototype,
  onYes,
  showModal,
  setShowModal,
  saving,
}) {
  return (
    <CpModal show={!!showModal} onClose={() => setShowModal(false)}>
      <CpModal.Header title="Are you sure you want to archive this?" />
      <CpModal.Body>{prototype?.name}</CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={() => onYes(prototype)}
          className="cp-mr-8"
          showLoader={saving}
        >
          Yes
        </CpButton>
        <CpButton btnType="flat" onClick={() => setShowModal(false)}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
